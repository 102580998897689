'use client';
import { sleep } from '@utils/delay';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';

import { GlobalItem } from '@/shared/types/common';
import { useStore } from '@/store/context';

export const WithShopItems = observer(
  ({
    children,
  }: {
    children: ({ items }: { items: GlobalItem[] }) => ReactNode;
  }) => {
    //const [cards, setCards] = useState<GlobalItem[]>([]);
    const shop = useStore()?.shop;

    /*    useEffect(() => {
      sleep(2000).then(() => setCards(() => shop?.cards));
    }, []);*/

    if (!children) {
      throw new Error('Children not provided');
    }
    return children({ items: shop.cards });
  },
);
