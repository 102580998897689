'use client';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './card-live-feed.module.scss';
import { CardLiveFeedEntityItemInfoProps } from './card-live-feed.types';

export const CardLiveFeedEntityItemInfo = observer(
  ({ info }: CardLiveFeedEntityItemInfoProps) => {
    const currencySign = useStore().app.getCurrencySign;

    const price = `${currencySign}${info?.price?.current}`;

    return (
      <>
        <div className={styles['price-tags']}>
          <Typography
            className={styles['price-tags--current']}
            size={'xl'}
            weight={'bold'}
          >
            {price}
          </Typography>

          {info?.price?.old && (
            <Typography
              className={styles['price-tags--old']}
              decoration={'line-through'}
              size={'m'}
              weight={'semi-bold'}
            >
              {currencySign}
              {info.price.old}
            </Typography>
          )}
        </div>
        <Typography
          className={cn(styles['title'])}
          size={'base'}
          weight={'medium'}
        >
          {info.title}
        </Typography>
      </>
    );
  },
);
