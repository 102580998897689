'use client';

import { useTimer } from '@hooks/client';
import cn from 'clsx';
import { useEffect, useMemo, useState } from 'react';

import { Illustration } from '@/shared/ui';
import { HeightAnimatedWrapper } from '@/shared/ui/height-animated-wrapper/height-animated-wrapper';
import { Typography } from '@/shared/ui/typography';

import styles from './additional-info.module.scss';
import {
  AdditionalInfoMobileCardProps,
  AdditionalInfoMobileIconSvgProps,
  AdditionalInfoMobileProps,
  AdditionalInfoMobileTimerProps,
  Statuses,
} from './additional-info.types';

export const AdditionalInfoMobile = ({
  className,
  children,
  variant,
  width = 'full-w',
  isExpandable = false,
  icon = <AdditionalInfoMobile.DefaultIcon variant={variant} />,
  ...props
}: AdditionalInfoMobileProps) => {
  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    className,
  );

  /*@TODO variant danger добавить*/

  if (isExpandable) {
    return (
      <AdditionalInfoMobile.Expandable
        width={width}
        variant={variant}
        icon={icon}
      >
        {children}
      </AdditionalInfoMobile.Expandable>
    );
  }

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info']}>{children}</Typography>
      {icon}
    </div>
  );
};

const min = 60;

AdditionalInfoMobile.Card = ({
  className,
  variant,
  width,
  icon,
  leftText,
  rightText,
  ...props
}: AdditionalInfoMobileCardProps) => {
  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles['additional-info--card'],
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    className,
  );

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info-card']}>{leftText}</Typography>
      <div className={styles['card-icon']}>
        <Typography className={styles['info-timer']}>{rightText}</Typography>
        <Illustration
          spanTagClassName={cn(
            styles['additional-info-icon-card'],
            styles[`additional-info-icon--${variant}`],
          )}
          spriteName={'icons'}
          name={icon}
        />
      </div>
    </div>
  );
};
// eslint-disable-next-line react/display-name
AdditionalInfoMobile.Timer = ({
  width = 'full-w',
  className,
  duration = 3600,
  status,
  onTimesOut,
  enableColorDiffByTime,
  ...props
}: AdditionalInfoMobileTimerProps) => {
  const statuses: Statuses = {
    timesUp: {
      variant: 'danger',
      icon: 'timer-cancel',
      text: "Time's up",
    },
    completed: {
      variant: 'success',
      icon: 'check-circle',
      text: 'Withdrawn',
    },
    canceled: {
      variant: 'danger',
      icon: 'close-circle',
      text: 'Cancelled by timeout',
    },
    claim: {
      variant: typeof duration === 'number' ? 'warn' : 'success',
      icon: 'clock',
      text: 'Claim within',
    },
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {
    timer,
    count,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useTimer({
    duration,
  });

  const variant = status ? statuses[status].variant : 'danger';
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!count) {
      onTimesOut && onTimesOut();
    }
  }, [count]);

  const additionalInfoClasses = cn(
    styles['additional-info'],
    styles['additional-info--timer'],
    { [styles['variant-danger']]: !enableColorDiffByTime },
    {
      [styles[
        `variant-${count > 259200 ? 'success' : count < 259201 && count > 86400 ? 'warn' : 'danger'}`
      ]]: enableColorDiffByTime,
    },
    styles[`width-${width}`],
    className,
  );

  const time = timer.startsWith('00')
    ? timer.substring(3)
    : timer.includes('days')
      ? timer
      : timer.substring(0, 5);

  return (
    <div className={additionalInfoClasses} {...props}>
      <Typography className={styles['info-timer']}>
        {statuses[status].text}
      </Typography>
      <div className={styles['timer-icon']}>
        <Typography className={styles['info-timer']}>
          {count ? time : ''}
        </Typography>
        <Illustration
          spanTagClassName={cn(
            styles['additional-info-icon-timer'],
            {
              [styles['additional-info-icon--danger']]: !enableColorDiffByTime,
            },
            {
              [styles[
                `additional-info-icon--${count > 259200 ? 'success' : count < 259201 && count > 86400 ? 'warn' : 'danger'}`
              ]]: enableColorDiffByTime,
            },
          )}
          spriteName={'icons'}
          name={status ? statuses[status].icon : 'clock'}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line react/display-name
AdditionalInfoMobile.Expandable = ({
  variant,
  width,
  className,
  children,
  icon,
  ...props
}: Omit<AdditionalInfoMobileProps, 'isExpandable'>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const additionalInfoClasses = cn(
    styles[`variant-${variant}`],
    styles[`width-${width}`],
    styles['expandable'],
    className,
  );
  const infoClasses = cn(styles['info'], { [styles['info-on']]: isExpanded });

  const handleExpand = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <div className={additionalInfoClasses} {...props} onClick={handleExpand}>
      <div className={styles['icon']}>{icon}</div>
      <HeightAnimatedWrapper className={styles['info-container']}>
        <Typography className={infoClasses}>{children}</Typography>
      </HeightAnimatedWrapper>
      <Illustration
        spanTagClassName={cn(
          styles['cheveron'],
          styles[`cheveron-${isExpanded ? 'up' : 'down'}`],
          styles[`additional-info-icon--${variant}`],
        )}
        spriteName={'icons'}
        name={'chevron-down'}
      />
    </div>
  );
};

// eslint-disable-next-line react/display-name
AdditionalInfoMobile.DefaultIcon = ({
  size,
  width,
  height,
  variant,
  name,
  withBackground,
  ...props
}: AdditionalInfoMobileIconSvgProps) => {
  return (
    <div
      className={cn(styles['with-background'], {
        [styles[`with-background--${variant}`]]: withBackground,
      })}
    >
      <Illustration
        id={'additional-info-icon'}
        spanTagClassName={cn(
          styles['additional-info-icon'],
          styles[`additional-info-icon--${variant}`],
        )}
        spriteName={'icons'}
        size={size}
        name={
          name
            ? name
            : variant === 'success'
              ? 'info-square'
              : variant === 'danger'
                ? 'info-circle'
                : 'info-triangle-warn'
        }
      />
    </div>
  );
};

AdditionalInfoMobile.Gift = () => {
  return <div></div>;
};
