import {
  CardLiveFeedEntityItemProps,
  CardLiveFeedEntityTagsProps,
  CardLiveFeedProps,
} from '@entities/desktop/cards/card-live-feed/ui/card-live-feed.types';
import { CardLiveFeedEntityItemInfo } from '@entities/desktop/cards/card-live-feed/ui/item-info';
import cn from 'clsx';

import { Item } from '@/shared/ui';
import { ItemRarityLiveFeed } from '@/shared/ui';
import { TagCategory } from '@/shared/ui/tag-category';

import styles from './card-live-feed.module.scss';

export const CardLiveFeedEntity = ({
  children,
  className,
  tagCategory,
  item,
  rarity,
  info,
  topSlot = <CardLiveFeedEntity.Item rarity={rarity} item={item} />,
  bottomSlot = tagCategory?.length &&
    tagCategory.length > 0 &&
    tagCategory?.map((category, idx) => (
      <TagCategory
        className={styles['tag-category']}
        key={`clfc-${category}-idx-${idx}`}
        variant={category}
      />
    )),
  ...props
}: CardLiveFeedProps) => {
  const cardLiveFeedClasses = cn(styles['card-live-feed'], className);

  return (
    <article className={cardLiveFeedClasses} {...props}>
      <div className={cn(styles['card-live-feed--content'])}>
        <div className={cn(styles['card-live-feed--content__info'])}>
          <CardLiveFeedEntityItemInfo info={info} />
        </div>
        <div className={cn(styles['card-live-feed--content__item'])}>
          {topSlot}
        </div>
        {tagCategory && (
          <CardLiveFeedEntity.Tags>{bottomSlot}</CardLiveFeedEntity.Tags>
        )}
      </div>
    </article>
  );
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Tags = ({ children }: CardLiveFeedEntityTagsProps) => {
  const cardLiveFeedEntityTagsClasses = cn(styles['card-live-feed--tags']);

  return <div className={cardLiveFeedEntityTagsClasses}>{children}</div>;
};

// eslint-disable-next-line react/display-name
CardLiveFeedEntity.Item = ({ item, rarity }: CardLiveFeedEntityItemProps) => {
  return (
    <>
      <ItemRarityLiveFeed
        size={'58'}
        className={styles['item-rarity']}
        variant={rarity}
      />
      <Item className={styles['item']} size={'96'} variant={item} />
    </>
  );
};
